import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "dc_content_container",
      class: _normalizeClass({
        'container-fluid': _ctx.contentWidthFluid,
        'container-xxl': !_ctx.contentWidthFluid
      })
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}