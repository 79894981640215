const zh_CN = {
  title: '管理',
  userManagement: '用户管理',
  projectManagement: '项目管理',
  users: '用户',
  roles: '角色',
  permissions: '权限',
  list: '列表',
  view: '详情',
  project: '项目',
  meta: {
    management: 'Meta 管理',
    page: '粉丝页'
  }
}

const en = {
  title: 'Admin',
  userManagement: 'User Management',
  projectManagement: 'Project Management',
  users: 'Users',
  roles: 'Roles',
  permissions: 'Permissions',
  list: 'List',
  view: 'View',
  project: 'APP',
  meta: {
    management: 'Meta Management',
    page: 'Page'
  }
}

export { zh_CN, en }
