import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { Actions } from '@/store/enums/StoreEnums'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'bole-sso',
    component: () => import('@/views/authentication/MultiStepSSO.vue')
  },
  {
    path: '/admin',
    component: () => import('@/layout/Layout.vue'),
    meta: {
      roles: ['administrator']
    },
    children: [
      {
        path: '/admin/project-management/list',
        name: 'project-list',
        component: () => import('@/views/admin/project-management/list/index.vue')
      },
      {
        path: '/admin/project-management/view',
        name: 'project-view',
        redirect: { name: 'project-overview' },
        component: () => import('@/views/admin/layout/ProjectLayoutWithTabs.vue'),
        children: [
          {
            path: 'overview',
            name: 'project-overview',
            component: () => import('@/views/admin/project-management/view/Overview.vue')
          },
          {
            path: 'setting',
            name: 'project-setting',
            component: () => import('@/views/admin/project-management/view/Setting.vue')
          }
        ]
      },
      {
        path: '/admin/rbac-management/roles',
        name: 'rbac-roles',
        component: () => import('@/views/admin/layout/ProjectLayout.vue'),
        children: [
          {
            path: 'list',
            name: 'rbac-roles-list',
            component: () => import('@/views/admin/rbac-management/roles/index.vue')
          },
          {
            path: 'view',
            name: 'rbac-roles-view',
            component: () => import('@/views/admin/rbac-management/roles/View.vue')
          }
        ]
      },
      {
        path: '/admin/rbac-management/permissions',
        name: 'rbac-permissions',
        component: () => import('@/views/admin/layout/ProjectLayout.vue'),
        children: [
          {
            path: 'list',
            name: 'rbac-permissions-list',
            component: () => import('@/views/admin/rbac-management/permissions/index.vue')
          }
        ]
      },
      {
        path: '/admin/user-management',
        name: 'user-management',
        component: () => import('@/views/admin/layout/ProjectLayout.vue'),
        redirect: { name: 'user-list' },
        children: [
          {
            path: '/admin/user-management/list',
            name: 'user-list',
            component: () => import('@/views/admin/user-management/list/main.vue')
          },
          {
            path: '/admin/user-management/view',
            name: 'user-view',
            component: () => import('@/views/admin/user-management/view/main.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/docs',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/docs/overview',
        name: 'docs-overview',
        component: () => import('@/views/docs/Overview.vue')
      },
      {
        path: '/docs/setup',
        name: 'docs-setup',
        component: () => import('@/views/docs/Setup.vue')
      },
      {
        path: '/docs/changelog',
        name: 'docs-changelog',
        component: () => import('@/views/docs/Changelog.vue')
      }
    ]
  },
  {
    path: '/me',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/me/profile',
        name: 'my-profile',
        component: () => import('@/views/profile/layout/index.vue'),
        children: [
          {
            path: 'overview',
            name: 'profile-overview',
            component: () => import('@/views/profile/Overview.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/authentication/Logout.vue')
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/authentication/Error404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to) => {
  await store.dispatch(Actions.VERIFY_AUTH)
  store.dispatch(Actions.VERIFY_ROLE, to.meta.roles)
  store.dispatch(Actions.VERIFY_PERMISSION, to.meta.permissions)

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
