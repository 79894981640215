import { CookieComponent } from '@/assets/ts/components'

const ID_TOKEN_KEY = 'id_token' as string

/**
 * @description get token form cookie
 */
export const getToken = (): string | undefined => {
  // return window.cookie.getItem(ID_TOKEN_KEY)
  return CookieComponent.get(ID_TOKEN_KEY)
}

/**
 * @description save token into cookie
 * @param token: string
 */
function getDomain() {
  const directList = ['localhost', '127.0.0.1']
  if (directList.includes(document.domain)) {
    return document.domain
  }
  return '.spinxbi.com'
}

export const saveToken = (token: string): void => {
  const today = new Date()
  today.setDate(today.getDate() + 13)
  CookieComponent.set(ID_TOKEN_KEY, token, {
    expires: today,
    domain: getDomain()
  })
}

/**
 * @description remove token form cookie
 */
export const destroyToken = (): void => {
  CookieComponent.set(ID_TOKEN_KEY, '', {
    'max-age': -1,
    'domain': getDomain()
  })
}

export default { getToken, saveToken, destroyToken }
