const zh_CN = {
  title: '操作',
  quickActions: '快捷操作',
  moreActions: '更多操作',
  edit: '修改',
  update: '更新',
  delete: '删除',
  refresh: '刷新',
  download: '下载',
  execute: '执行',
  redirect: '跳转',
  close: '关闭'
}

const en = {
  title: 'Actions',
  quickActions: 'Quick Actions',
  moreActions: 'More Actions',
  edit: 'Edit',
  update: 'Update',
  delete: 'Delete',
  refresh: 'Refresh',
  download: 'Download',
  execute: 'Execute',
  redirect: 'Redirect',
  close: 'Close'
}

export { zh_CN, en }
