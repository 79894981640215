
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { translate } from '@/core/helpers/common'
import { subheaderDisplay, toolbarWidthFluid, contentWidthFluid } from '@/core/helpers/config'
import HtmlClass from '@/core/services/LayoutService'

export default defineComponent({
  name: 'MainContent',
  setup() {
    onMounted(() => {
      HtmlClass.initToolbar()
    })

    const store = useStore()
    const pageTitle = computed(() => {
      return store.getters.pageTitle
    })
    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath
    })

    return {
      subheaderDisplay,
      toolbarWidthFluid,
      translate,
      pageTitle,
      breadcrumbs,
      contentWidthFluid
    }
  }
})
