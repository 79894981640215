import _ from 'lodash'
import ApiService from '@/core/services/ApiService'
import JwtService from '@/core/services/JwtService'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import type { Error, User, UserAuthInfo } from '@/data/interfaces/admin'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosRequestConfig } from 'axios'

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  error = {} as Error
  user = {} as User
  isAuthenticated = !!JwtService.getToken()

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated
  }

  /**
   * Get authentification error
   * @returns array
   */
  get getError() {
    return this.error
  }

  @Mutation
  [Mutations.SET_ERROR](err: Error) {
    this.error = { ...err }
  }

  @Mutation
  [Mutations.SET_AUTH](user: User) {
    this.user = user
    this.isAuthenticated = true
    this.error = {}
    const { api_token } = user

    if (api_token) {
      JwtService.saveToken(api_token)
    }
  }

  @Mutation
  [Mutations.SET_USER](user: User) {
    this.user = user
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.user = {} as User
    this.isAuthenticated = false
    this.error = {}
    JwtService.destroyToken()
  }

  @Action
  async [Actions.DINGTALK](query: any) {
    try {
      const { data } = await ApiService.query('dingtalk', {
        params: { ...query }
      })
      this.context.commit(Mutations.SET_AUTH, data)
    } catch ({ response }) {
      this.context.commit(Mutations.SET_ERROR, {
        message: '钉钉认证失败'
      })
    }
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH)
  }

  @Action
  async [Actions.VERIFY_AUTH](payload: AxiosRequestConfig) {
    if (JwtService.getToken()) {
      return ApiService.post('verify_token', payload)
        .then(async ({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data)
          const { projects, roles } = data
          this.context.commit(Mutations.SET_AUTHORIZED_PROJECTS, projects)
          if (roles.includes('administrator')) {
            await this.context.dispatch(Actions.QUERY_ADMIN_PROJECTS)
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR)
          this.context.commit(Mutations.PURGE_AUTH)
        })
    } else {
      this.context.commit(Mutations.PURGE_AUTH)
    }
  }

  @Action
  [Actions.VERIFY_ROLE](roles: undefined | Array<string>) {
    if (!roles || !roles.length) {
      return
    }
    if (_.intersection(this.user.roles, roles).length) {
      return
    }
    this.context.commit(Mutations.PURGE_AUTH)
  }

  @Action
  [Actions.VERIFY_PERMISSION](permissions: undefined | Array<string>) {
    if (!permissions || !permissions.length) {
      return
    }
    if (_.intersection(this.user.permissions, permissions).length) {
      return
    }
    this.context.commit(Mutations.PURGE_AUTH)
  }
}
