import { createI18n } from 'vue-i18n/index'
import { zh_CN as globalCN, en as globalEN } from './global'
import { zh_CN as actionsCN, en as actionsEN } from './actions'
import { zh_CN as adminCN, en as adminEN } from './admin'
import { zh_CN as supportCN, en as supportEN } from './support'
import { zh_CN as departmentCN, en as departmentEN } from './departments'

const messages = {
  zh_CN: {
    game: '游戏',
    global: {
      ...globalCN,
      actions: actionsCN
    },
    admin: adminCN,
    support: supportCN,
    department: departmentCN,
    dashboard: '看板',
    layoutBuilder: '自定义布局',
    craft: '个人',
    pages: '参考',
    profile: '个人信息',
    profileOverview: '个人概览',
    projects: '项目',
    campaigns: '广告系列',
    documents: '文档',
    connections: '链接',
    wizards: '指引',
    horizontal: '水平',
    vertical: '竖直',
    account: '账户',
    overview: '概览',
    accountOverview: '概览',
    settings: '设置',
    authentication: '认证',
    basicFlow: '基础流程',
    signIn: '登录',
    signUp: '注册',
    passwordReset: '重置密码',
    error404: '404异常',
    error500: '500错误',
    apps: '应用',
    chat: '消息',
    privateChat: '私聊',
    groupChat: '群聊',
    drawerChat: '聊天窗',
    widgets: '小部件',
    widgetsLists: '列表部件',
    widgetsStatistics: '统计部件',
    widgetsCharts: '图表部件',
    widgetsMixed: '混合部件',
    widgetsTables: '表',
    widgetsFeeds: '信息流',
    changelog: '发版日志',
    docsAndComponents: '文档 & 组件',
    megaMenu: 'Mega目录',
    exampleLink: '示例链接',
    modals: '弹出框',
    general: '常规',
    inviteFriends: '邀请朋友',
    viewUsers: '查看用户',
    upgradePlan: '升级方案',
    shareAndEarn: '分享',
    forms: '表单',
    newTarget: '创建对象',
    newCard: '创建卡片',
    newAddress: '创建地址',
    createAPIKey: '创建API Key',
    twoFactorAuth: '双重验证',
    createApp: '创建App',
    createAccount: '创建用户',
    documentation: '文档',
    components: '组件',
    resources: '参考',
    activity: '活动',
    customers: '消费者',
    gettingStarted: '引导',
    customersListing: '用户列表',
    customerDetails: '用户信息',
    calendarApp: '日历',
    subscriptions: '订阅',
    getStarted: '入门',
    subscriptionList: '订阅列表',
    addSubscription: '添加订阅',
    viewSubscription: '查看订阅'
  },
  en: {
    game: 'Game',
    global: {
      ...globalEN,
      actions: actionsEN
    },
    admin: adminEN,
    support: supportEN,
    department: departmentEN,
    dashboard: 'Dashboard',
    layoutBuilder: 'Layout builder',
    craft: 'Crafted',
    pages: 'Pages',
    profile: 'Profile',
    profileOverview: 'Overview',
    projects: 'Projects',
    campaigns: 'Campaigns',
    documents: 'Documents',
    connections: 'Connections',
    wizards: 'Wizards',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Account',
    overview: 'Overview',
    accountOverview: 'Overview',
    settings: 'Settings',
    authentication: 'Authentication',
    basicFlow: 'Basic Flow',
    signIn: 'Sign-in',
    signUp: 'Sign-up',
    passwordReset: 'Password Reset',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Apps',
    chat: 'Chat',
    privateChat: 'Private Chat',
    groupChat: 'Group Chat',
    drawerChat: 'Drawer Chat',
    widgets: 'Widgets',
    widgetsLists: 'Lists',
    widgetsStatistics: 'Statistics',
    widgetsCharts: 'Charts',
    widgetsMixed: 'Mixed',
    widgetsTables: 'Tables',
    widgetsFeeds: 'Feeds',
    changelog: 'Changelog',
    docsAndComponents: 'Docs & Components',
    megaMenu: 'Mega Menu',
    exampleLink: 'Example link',
    modals: 'Modals',
    general: 'General',
    inviteFriends: 'Invite Friends',
    viewUsers: 'View Users',
    upgradePlan: 'Upgrade Plan',
    shareAndEarn: 'Share & Earn',
    forms: 'Forms',
    newTarget: 'New Target',
    newCard: 'New Card',
    newAddress: 'New Address',
    createAPIKey: 'Create API Key',
    twoFactorAuth: 'Two Factor Auth',
    createApp: 'Create App',
    createAccount: 'Create Account',
    documentation: 'Documentation',
    components: 'Components',
    resources: 'Resources',
    activity: 'Activity',
    customers: 'Customers',
    gettingStarted: 'Getting Started',
    customersListing: 'Customers Listing',
    customerDetails: 'Customers Details',
    calendarApp: 'Calendar',
    subscriptions: 'Subscriptions',
    getStarted: 'Getting Started',
    subscriptionList: 'Subscription List',
    addSubscription: 'Add Subscription',
    viewSubscription: 'View Subscription'
  }
}

const i18n = createI18n({
  legacy: false,
  locale: 'zh_CN',
  globalInjection: true,
  messages
})

export default i18n
