const zh_CN = {
  title: '部门',
  bi: 'BI',
  platform: '平台',
  ot: '运营'
}

const en = {
  title: 'Department',
  bi: 'BI',
  platform: 'Platform',
  ot: 'OT'
}

export { zh_CN, en }
