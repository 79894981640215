import { setLocale } from 'yup'
import * as en from './en'
import * as zh_CN from './zh_CN'
import i18n from '../i18n'

const initializeYupLocale = () => {
  const lang = i18n.global.locale.value

  if (lang === 'zh_CN') {
    setLocale(zh_CN)
  } else {
    setLocale(en)
  }
}
export default initializeYupLocale
