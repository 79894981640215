import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import i18n from '@/core/plugins/i18n/index'

//imports for app initialization
import ApiService from '@/core/services/ApiService'
import { initApexCharts } from '@/core/plugins/apexcharts'
import { initInlineSvg } from '@/core/plugins/inline-svg'
import { initVeeValidate } from '@/core/plugins/vee-validate'
import DLBasicContent from '@/layout/content/BasicContent.vue'
import DLMainContent from '@/layout/content/MainContent.vue'

import '@/core/plugins/prismjs'
const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus, { locale: zhCn })

app.component('DLBasicContent', DLBasicContent)
app.component('DLMainContent', DLMainContent)

ApiService.init(app)
initApexCharts(app)
initInlineSvg(app)
initVeeValidate()

app.use(i18n)

app.mount('#app')
