export enum CacheFields {
  MINIMIZE_PROJECT_CARD = 'minimize_project_card',
  PROJECT_ID = 'project_id'
}

export const getCache = (field: CacheFields): string | null => {
  return window.localStorage.getItem(field)
}

export const saveCache = (field: CacheFields, value: string): void => {
  window.localStorage.setItem(field, value)
}

export const destroyCache = (field: CacheFields): void => {
  window.localStorage.removeItem(field)
}

export default { CacheFields, getCache, saveCache, destroyCache }
