
import { defineComponent, onMounted } from 'vue'
import { contentWidthFluid } from '@/core/helpers/config'
import HtmlClass from '@/core/services/LayoutService'

export default defineComponent({
  name: 'KContent',
  setup() {
    onMounted(() => {
      HtmlClass.removeToolbar()
    })
    return {
      contentWidthFluid
    }
  }
})
