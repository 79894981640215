import i18n from '../plugins/i18n'

const { t, te } = i18n.global
export const translate = (text: string) => {
  const str = String(text)
  if (te(str)) {
    return t(str)
  } else {
    return str
  }
}
