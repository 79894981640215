const zh_CN = {
  email: '邮箱',
  password: '密码',
  demo: "体验账户请使用demo{'@'}bolegames.com",
  forgotPass: '忘记密码？',
  continue: '继续',
  wait: '请稍等...',
  loading: '加载中...',
  or: '或',
  viaEmail: '使用邮箱登录',
  ding: '钉钉',
  dingtalk: '使用钉钉扫码登录',
  home: '主页',
  dashboard: '看板',
  settings: '设置',
  signIn: '登录',
  signUp: '注册',
  pleaseInput: '请输入',
  error404: '404异常',
  error500: '500错误',
  others: '其它',
  table: {
    empty: '无数据',
    confirm: '确认',
    delConfirm: '确认删除该项吗？',
    cancel: '取消',
    delete: '删除'
  },
  setting: {
    myProfile: '个人信息',
    myProject: '我的项目',
    mySubs: '我的订阅',
    subscription: {
      notifications: '通知'
    },
    language: '语言',
    theme: {
      appearance: '外观',
      dark: '深色',
      light: '浅色',
      auto: '自动'
    },
    logout: '退出'
  },
  ad: {
    spend: '广告花费'
  },
  profile: {
    location: '北京, 朝阳'
  }
}

const en = {
  email: 'Email',
  password: 'Password',
  demo: "Please use demo{'@'}bolegames.com to log in to demo experience",
  forgotPass: 'Forgot Password ?',
  continue: 'Continue',
  wait: 'Please wait...',
  loading: 'Loading...',
  or: 'OR',
  viaEmail: 'Use email to log in',
  ding: 'DingTalk',
  dingtalk: 'Use DingTalk code to log in',
  home: 'HOME',
  dashboard: 'Dashboard',
  settings: 'Settings',
  signIn: 'Sign-in',
  signUp: 'Sign-up',
  pleaseInput: 'Please Input',
  error404: 'Error 404',
  error500: 'Error 500',
  others: 'Others',
  table: {
    empty: 'No data found',
    confirm: 'Confirm',
    delConfirm: 'Are you sure you want to delete this item?',
    cancel: 'Cancel',
    delete: 'Delete'
  },
  setting: {
    myProfile: 'My Profile',
    myProject: 'My Projects',
    mySubs: 'My Subscription',
    subscription: {
      notifications: 'Notifications'
    },
    language: 'Language',
    theme: {
      appearance: 'Appearance',
      dark: 'Dark',
      light: 'Light',
      auto: 'Auto'
    },
    logout: 'Log Out'
  },
  ad: {
    spend: 'Ad Spend'
  },
  profile: {
    location: 'Beijing, Chaoyang'
  }
}

export { zh_CN, en }
