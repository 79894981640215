
import { defineComponent, nextTick, onMounted } from 'vue'
import { initializeComponents } from '@/core/plugins/custom'
import initializeYupLocale from '@/core/plugins/yup-locale'

export default defineComponent({
  name: 'app',
  setup() {
    onMounted(() => {
      nextTick(() => {
        initializeComponents()
        initializeYupLocale()
      })
    })
  }
})
