import ApiService from '@/core/services/ApiService'
import { CreateProjectSSO, UpdateProjectSSO, UpdateProjectMmebers } from '@/data/interfaces/admin'

interface ProjectMatchParams {
  search?: string
  order?: string
}

export function createProjectSSO(body: CreateProjectSSO) {
  return ApiService.post('admin/project/update', body)
}

export function updateProjectSSO(body: UpdateProjectSSO) {
  return ApiService.post('/admin/project/update', body)
}

export function getAdminProjects(params: ProjectMatchParams) {
  return ApiService.query('/admin/projects/list', params)
}

export function updateProjectMembers(body: UpdateProjectMmebers) {
  return ApiService.post(`/admin/${body._id}/users`, body)
}
