import CacheService from '@/core/services/CacheService'
import { getAdminProjects } from '@/api/admin/Project'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import type { Error, Project, LooseObject } from '@/data/interfaces/admin'

@Module
export default class ProjectModule extends VuexModule {
  error = {} as Error
  project = {} as Project
  myAuthorizedProjects = [] as Array<Project>
  myAdminProjects = [] as Array<Project>

  /**
   * Get query errors
   * @returns array
   */
  get projectErrors() {
    return this.error
  }

  /**
   * Get current user project
   * @returns Project
   */
  get currentProject(): Project {
    return this.project
  }

  /**
   * Get cached user projects
   * @returns Projects
   */
  get authorizedProjects(): Project[] {
    return this.myAuthorizedProjects
  }

  /**
   * Get cached user managed projects
   * @returns Projects
   */
  get adminProjects(): Project[] {
    return this.myAdminProjects
  }

  @Mutation
  [Mutations.SET_ERROR](errs: Error) {
    this.error = errs
  }

  @Mutation
  [Mutations.SET_PROJECT_ID](id: string) {
    CacheService.saveCache(CacheService.CacheFields.PROJECT_ID, id)
  }

  @Mutation
  [Mutations.SET_PROJECT](obj: Project) {
    this.project = obj
  }

  @Mutation
  [Mutations.SET_AUTHORIZED_PROJECTS](obj: Project[]) {
    this.myAuthorizedProjects = obj
  }

  @Mutation
  [Mutations.SET_ADMIN_PROJECTS](obj: Project[]) {
    this.myAdminProjects = obj
  }

  @Action
  async [Actions.QUERY_ADMIN_PROJECTS](payload: LooseObject) {
    if (this.myAdminProjects.length) {
      return
    }
    return getAdminProjects(payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ADMIN_PROJECTS, data)
      })
      .catch(() => {
        this.context.commit(Mutations.SET_ERROR, [
          {
            scope: 'api',
            message: '请求项目列表失败'
          }
        ])
      })
  }

  @Action({ rawError: true })
  [Actions.QUERY_PROJECT]() {
    if (this.project && Object.keys(this.project).length) {
      return
    }

    const projectId = CacheService.getCache(CacheService.CacheFields.PROJECT_ID)
    const data = this.myAdminProjects.find((x) => x._id === projectId)
    if (data) {
      this.context.commit(Mutations.SET_PROJECT, data)
    } else {
      this.context.commit(Mutations.SET_PROJECT, this.myAdminProjects[0])
    }
  }

  @Action
  [Actions.CHANGE_PROJECT](id: string) {
    const data = this.myAdminProjects.find((x) => x._id === id)
    if (data) {
      CacheService.saveCache(CacheService.CacheFields.PROJECT_ID, id)
      this.context.commit(Mutations.SET_PROJECT, data)
    } else {
      this.context.commit(Mutations.SET_PROJECT, this.myAdminProjects[0])
    }
  }
}
