const zh_CN = {
  title: '支持',
  about: '关于我们',
  faq: '常见问题'
}

const en = {
  title: 'Support Center',
  about: 'About Us',
  faq: 'FAQ'
}

export { zh_CN, en }
